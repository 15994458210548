<template>
  <div>
    <h1 class="text-2xl mb-6">quickwin</h1>
    <div v-if="noData">
      Merci de remplir le formulaire afin d'obtenir les données.
    </div>
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold">
        Comparatif
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchComp"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1 ml-6 mr-6"
        :headers="ComparatifdynamicHeaders"
        :items="itemsComparatif"
        :search="searchComp"
      >
        <template
          v-for="header in ComparatifdynamicHeaders"
          v-slot:[`item.${header.value}`]="{ item }"
        >
          {{
            header.value.startsWith("Position_") && item[header.value] === 9999
              ? "Non Positionné"
              : item[header.value]
          }}
        </template></v-data-table
      >
    </v-card>
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold">
        Quickwin
        <v-spacer></v-spacer>
        <v-select
          v-model="selectedProvenance"
          :items="Provenances"
          label="Select Provenance"
        ></v-select>
        <v-spacer></v-spacer>
        <v-text-field
          class="mb-6"
          v-model="searchQuick"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <dot-menu
          @exportCsv="exportCSV"
          :csvButton="{
            csvData,
            filename: `export_Quickwin.csv`,
          }"
        ></dot-menu>
      </v-card-title>
      <v-data-table
        :key="`quick-${paginationQuick.page}-${searchQuick}`"
        class="elevation-1 ml-6 mr-6"
        :headers="QuickWindynamicHeaders"
        :items="itemsQuickwin"
        :search="searchQuick"
      >
        <template
          v-for="header in ComparatifdynamicHeaders"
          v-slot:[`item.${header.value}`]="{ item }"
        >
          {{
            header.value.startsWith("Position_") && item[header.value] === 9999
              ? "Non Positionné"
              : item[header.value]
          }}
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import DotMenu from "@/components/common/menus/DotMenu";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "Quickwin",
  components: {
    DotMenu,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  data() {
    return {
      csvData: "",
      icons: {
        mdiMagnify,
      },
      searchComp: "",
      searchQuick: "",
      selectedProvenance: null,
      Provenances: [],
      paginationComp: {
        page: 1,
        itemsPerPage: 10,
      },
      paginationQuick: {
        page: 1,
        itemsPerPage: 10,
      },
    };
  },
  created() {
    this.Provenances = this.uniqueProvenances();
  },
  methods: {
    uniqueProvenances() {
      const provenances = this.itemsQuickwin.map((item) => item.Provenance);
      return ["All", ...new Set(provenances)];
    },
    async exportCSV() {
      const data = this.getData.Comparatif;
      this.csvData = this.arrayToCsv(data);
    },
    arrayToCsv(data) {
      // Get the headers (keys) of the objects
      const headers = Object.keys(data[0]);
      // Create a row for each object
      const rows = data.map((obj) =>
        headers.map((header) => obj[header]).join(",")
      );
      // Combine the headers and rows
      return [headers.join(","), ...rows].join("\n");
    },
  },
  computed: {
    noData() {
      if (!Array.isArray(this.getData.Comparatif)) {
        return true;
      } else {
        return false;
      }
    },
    getData() {
      return this.$store.getters["seo/getData"];
    },
    itemsComparatif() {
      return this.getData.Comparatif;
    },
    itemsQuickwin() {
      let items = this.getData.Quickwin;
      if (this.selectedProvenance && this.selectedProvenance !== "All") {
        items = items.filter(
          (item) => item.Provenance === this.selectedProvenance
        );
      }
      return items || [];
    },

    ComparatifdynamicHeaders() {
      if (!Array.isArray(this.getData.Comparatif)) {
        return [];
      } else {
        let firstItem = this.itemsComparatif[0];

        let positionKeys = Object.keys(firstItem).filter((key) =>
          key.startsWith("Position_")
        );

        let positionHeaders = positionKeys.map((key) => ({
          text: key.replace(/_/g, " "),
          value: key,
        }));

        return [
          { text: "Url Site", value: "Url_Site" },
          { text: "Keyword", value: "Keyword" },
          { text: "Keyword Difficulty Site", value: "Keyword Difficulty_Site" },
          { text: "Search Volume site", value: "Search Volume_Site" },
          { text: "Catégorie", value: "Categorie_Site" },
          { text: "Catégorie 2 ", value: "Catégorie 2_Site" },
          { text: "Traffic", value: "Traffic_Site" },
          ...positionHeaders,
          { text: "acteurs positionnés", value: "nb acteurs positionnés" },
        ];
      }
    },
    QuickWindynamicHeaders() {
      if (!Array.isArray(this.getData.Comparatif)) {
        return [];
      } else {
        let firstItem = this.itemsQuickwin[0];

        let positionKeys = Object.keys(firstItem).filter((key) =>
          key.startsWith("Position_")
        );

        let positionHeaders = positionKeys.map((key) => ({
          text: key.replace(/_/g, " "),
          value: key,
        }));

        return [
          { text: "Keyword", value: "Keyword" },
          { text: "Keyword Difficulty", value: "Keyword Difficulty_Site" },
          { text: "URL", value: "Url_Site" },
          { text: "Search Volume", value: "Search Volume_Site" },
          { text: "Provenance", value: "Provenance" },
          { text: "Catégorie", value: "Categorie_Site" },
          { text: "Catégorie 2 ", value: "Catégorie 2_Site" },
          { text: "Traffic", value: "Traffic_Site" },
          ...positionHeaders,
          { text: "acteurs positionnés", value: "nb acteurs positionnés" },
        ];
      }
    },
  },
};
</script>

<style scoped></style>
